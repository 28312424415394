.app-input {
  background: #ffffff;
  border: 1px solid #eaecf3;
  border-radius: 12px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    padding: 7px 18px;
    border-radius: 12px;
    width: 100%;
    border: none;
    outline: none;
    &:focus {
      outline: none;
    }
  }

  .icon {
    padding-right: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      color: #69768b;
      font-size: 20px;
    }
  }
  &.has-danger {
    border: 1px solid #d74242ed;
  }
  &.has-success {
    border: 1px solid #99f59d;
  }
}

@media screen and (max-width: 395px) {
  .app-input {
    input {
      &::placeholder {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  .app-input {
    input::placeholder {
      font-size: 3.5vw;
    }
  }
}
