$theme-colors: (
  "primary": #2f2a89,
  "dark": #383941,
  "dark-2": #505662,
  "light": #69768b,
  "background": #f5f5f5,
  "white": #fff,
  "gray": #6f757e,
  "icon": #b9b6cb,
  "danger": #bf4545,
  "icon-2": #ededf7,
  "icon-3": #f0fbfa,
  "icon-4": #ecfaed,
  "green": #29bcc6,
  "blue": #6e7fd4,
  "gray-2": #e2e2e2,
  "gray-3": #f9f9f9,
  "icon-5": #fff8ea,
  "icon-6": #faecec,
  "activeBg-1": #f4edf6,
  "activeBg-2": #eef8f6,
  "activeBg-3": #f6eded,
  "activeBg-4": #eef6f8,
  "activeBg-5": #f4f7ed,
  "activeBg-6": #f3f2ff,
  "activeBg-7": #f2f8ee,
  "activeBg-8": #f5eded,
  "background-2": #f7f7fb,
  "background-3": #d84560,
);

@import "~bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
