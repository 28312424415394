.listCardContainer {
  box-shadow: 0px 60px 120px rgba(75, 75, 78, 0.2);
  border-radius: 8px;
  background-color: #fff;
}

.deviceStatus {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.deviceOnStatus {
  background-color: #3ecd7b;
}

.deviceOffStatus {
  background-color: #cd5b3e;
}

.deviceName {
  font-weight: 500;
  font-size: 14px;
  color: #383941;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
