@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import "~react-toastify/dist/ReactToastify.min.css";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;600&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-bg: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #2f2a89;
}

.modal-lg {
  max-width: 730px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: rgb(128, 128, 128);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(66, 66, 66);
  border-radius: 20px;
}

.error {
  font-size: 12px;
  color: #d74242;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.bg-red {
  background-color: #d74242;
}

.table {
  --bs-table-bg: none;
}
