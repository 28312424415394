@function addTransition($property) {
  @return $property 200ms ease-in;
}

.main-container {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;

  .navbar-container {
    z-index: 2;
    transition: addTransition(all);
  }

  .navbar-container-notCollapsed {
    width: 250px;
  }

  .navbar-container-collapsed {
    width: 90px;
  }

  .main-content {
    transition: addTransition(padding-left);
    height: 100vh;

    .header-container {
      position: fixed;
      z-index: 1;
      background-color: #f4f5f5;

      .header {
        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #383941;
        }

        .title-long {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #383941;
        }

        .circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }

    .content-container {
      height: calc(100vh - 90px);
      overflow-y: auto;
      margin-top: 100px;
    }
  }

  .main-content-notCollapsed {
    width: calc(100vw - 250px);
  }

  .main-content-collapsed {
    width: calc(100vw - 90px);
  }
}

@media (max-width: 991px) {
  .main-container {
    .main-content {
      .header-container {
        .header {
          .title-long {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main-container {
    .navbar-container {
      display: none !important;
    }
    .navbar-container-notCollapsed {
      left: -250px;
    }
    .navbar-container-collapsed {
      left: -130px;
    }
    .main-content-notCollapsed,
    .main-content-collapsed {
      width: 100%;
    }
    .header {
      .open-icon {
        display: block !important;
      }
      .collapse-icon {
        display: none !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .main-container {
    .main-content {
      .header-container {
        .header {
          .title-long {
            font-size: 14px;
          }
          .title {
            font-size: 15px;
          }
        }
      }
    }
  }
}
