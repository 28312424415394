.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-text--keyboard-selected {
  padding: 0.3em;
  border-radius: 8px;
  background-color: #2f2a89 !important;
  color: white;
  &:hover {
    background-color: #2f2a89 !important;
  }
}

.react-datepicker__year .react-datepicker__year-text--selected {
  padding: 0.3em;
  border-radius: 8px;
  background-color: #2f2a89 !important;
  color: white;
  &:hover {
    background-color: #2f2a89 !important;
  }
}

.react-datepicker {
  border-radius: 0.8rem;
  font-family: "Poppins";
  color: #69768b;
  border: none;
  box-shadow: 0px 6px 16px rgba(193, 193, 193, 0.25);
  width: 250px;
  top: 8px;
}

.react-datepicker__header {
  border-bottom: none;
  border-top-left-radius: 1rem;
  background-color: #f8f8fd !important;
  color: #69768b;
  width: 250px;
}

.month-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-weight: 500;
  border: 1px solid #eaecf3;
  border-radius: 12px;
  background-color: #ffffff;
  color: #69768b;
  font-size: 12px;
  position: relative;
  height: 44px;
  min-width: 120px;
}

.react-datepicker__month-wrapper {
  margin-top: 0.5rem;
}

.react-datepicker__year-wrapper {
  margin-top: 0.5rem;
  max-width: none;
}

.react-datepicker__navigation-icon {
  top: 5px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 1rem;
}

.react-datepicker__month {
  margin-top: 0.5rem;
}

.react-datepicker__year {
  margin: 1.4rem;
}

.react-datepicker__year .react-datepicker__year-text {
  padding: 4px;
}

.react-datepicker__current-month {
  color: #69768b;
}

.react-datepicker__day-name {
  color: #69768b;
  font-weight: 500;
}

.react-datepicker__day {
  color: #69768b;
  font-weight: 500;
}

.react-datepicker__day--selected {
  border-radius: 9px;
  background: #2f2a89;
  color: #ffffff;
  &:hover {
    border-radius: 9px;
    background: #2f2a89;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: #ffffff;
  &:hover {
    background-color: #f5f5f5;
  }
}
