.container {
  text-align: left;
  line-height: 12px;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-weight: 500;
    font-size: 10px;
    color: #69768b;
  }
  .value {
    font-weight: 600;
    font-size: 18px;
    color: #383941;
  }
  .units {
    font-weight: 500;
    font-size: 12px;
    color: #69768b;
  }
}
