.schedule-sub-space-container {
  box-shadow: 0px 0px 13px rgba(214, 214, 214, 0.25);
  border-radius: 12px;
}

.scrollContainer {
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .scrollContent {
    display: flex;
    width: 100%;
    transition: transform 0.5s ease;
    padding: 0 16px;
  }
}

.weekdays-circle {
  width: 5px;
  height: 5px;
  background-color: #6e7fd4;
  border-radius: 80px;
}

.weekends-circle {
  width: 5px;
  height: 5px;
  background-color: #29bac4;
  border-radius: 80px;
}

.empty-circle {
  width: 5px;
  height: 5px;
  background-color: #f96163;
  border-radius: 80px;
}
