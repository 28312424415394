.TV {
  background: #f4eef8;
  color: #4f0074;
}

.Refrigerator {
  background: #eef8f6;
  color: #2a8389;
}

.Microwave.Oven {
  background: #eef6f8;
  color: #004374;
}

.Microwave {
  background: #eef6f8;
  color: #004374;
}

.Bulb {
  background: #eef8ee;
  color: #007427;
}

.Computer {
  background: #f7f8ee;
  color: #746f00;
}

.Home-theater {
  background: #f8eeee;
  color: #892a2a;
}

.Iron {
  background: #eef1f8;
  color: #5a58cc;
}

.Washing.Machine {
  background: #f7f8ee;
  color: #adb015;
}

.Air.Conditioner {
  background: #f8f4ee;
  color: #ebab2f;
}

.Fan {
  background: #eef8f8;
  color: #02afba;
}

.Light {
  background: #eef8f0;
  color: #5a892a;
}

.Water.Heater {
  background: #f8eeee;
  color: #892a2a;
}

.AC {
  background: #f8f4ee;
  color: #ebab2f;
}

.All.Devices {
  background: #f4eef8;
  color: #2f2a89;
}
