.app-modal-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.623) !important;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: visibility 210ms, opacity 200ms ease-out;
    .app-modal-container {
      max-height: 100%;
      width: 700px;
      background-color: white;
      padding: 40px 50px;
      border-radius: 10px;
      transition: transform 200ms ease-out;
      overflow: auto;
    }
    .app-modal-container::-webkit-scrollbar {
      width: 8px;
    }
  
    .app-modal-container::-webkit-scrollbar-track {
      background: rgb(128, 128, 128);
    }
  
    .app-modal-container::-webkit-scrollbar-thumb {
      background-color: rgb(66, 66, 66);
      border-radius: 20px;
    }
    .modal-sm {
      width: 400px;
    }
    .modal-md {
      width: 600px;
    }
    .modal-lg {
      width: 700px;
    }
  }
  .not-showing-modal {
    visibility: hidden;
    opacity: 0;
    .app-modal-container {
      transform: translateY(-100px);
    }
  }
  .showing-modal {
    visibility: visible;
    opacity: 1;
    .app-modal-container {
      transform: translateY(0px);
    }
  }
  
  @media (max-width: 500px) {
    .app-modal-backdrop {
      .app-modal-container {
        padding: 20px 20px;
      }
    }
  }
  