.drop-down-container {
  position: relative;

  .menu-container {
    position: absolute;
    background-color: #ffffff;
    border: 1.5px solid #f5f6f7;
    box-shadow: 0px 6px 16px rgba(193, 193, 193, 0.25);
    border-radius: 8px;

    overflow: hidden;
    z-index: 5;

    ul {
      padding: 0;
      list-style: none;
      width: max-content;
      margin: 0;

      li {
        cursor: pointer;
        padding: 15px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 400;
        font-size: 12px;
        color: #69768b;

        &:hover {
          background-color: rgba(221, 221, 221, 0.137);
        }

        &:not(:last-child) {
          border-bottom: 0.5px solid #f4f6fa;
        }
      }
    }

    .badge {
      background-color: #F4F4F8;
      font-size: 8px;
      color: #69768B;
      font-weight: 400;
      border-radius: 4px;
    }
  }

  .drop-down-icon {
    width: 20px;
    height: 26px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    background: #fafafe;
    box-shadow: 0px 5px 14px 3px rgba(38, 51, 77, 0.08);
  }

  .menu-top {
    top: 0px;
  }

  .menu-bottom {
    bottom: 0px;
  }

  .menu-left {
    left: 20px;
  }

  .menu-right {
    right: 20px;
  }

  .show-menu {
    display: block !important;
  }

  .hide-menu {
    display: none;
  }
}
