.spaceName {
  color: #383941;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.icon {
  border-radius: 6px;
  color: #2f2a89;
}

.iconBackgroundColor {
  background-color: #f4eef8;
}
