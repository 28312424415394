.day {
  width: 6px;
  height: 5px;
}

.weekday {
  border-radius: 74px 74px 0px 0px;
}

.weekend {
  border-radius: 0px 0px 74px 74px;
}
