.search-wrapper {
  border: 1px solid #eaecf3;
  border-radius: 12px 12px;
  height: 40px;
  background-color: #ffffff;
  .search-input,
  .search-input:focus {
    border-style: none;
    outline: none;
    height: 36px;
    font-size: 12px;
    &::placeholder {
      font-size: 12px !important;
      color: #69768b;
      font-weight: 500;
    }
    border-radius: 12px 0 0 12px;
    padding-left: 15px !important;
    margin-bottom: 2px;
  }
}
