.spinnerContainer {
  color: #afafaf;

  .text {
    font-size: 12px;
    font-weight: 400;
  }

  .spinner {
    width: 10px;
    height: 10px;
    border: 1.5px solid #afafaf;
    border-right-color: transparent;
  }
}
