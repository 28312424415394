.space-container {
  background: #ffffff;
  box-shadow: 0px 24px 120px rgba(38, 51, 77, 0.05);
  border-radius: 8px;
  transition: height 200ms ease-in;
  border: 1.5px solid #f5f6f7;
  .pc-header {
    span {
      font-size: 24px;
    }
    label {
      font-size: 16px;
      color: #383941;
    }
    .d-arrow {
      transition: transform 200ms ease-in;
    }
  }
}
