.checkBoxContainer {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 25px;
  
    font-weight: 400;
    font-size: 16px;
    color: #69768B;
  
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background: #ffffff;
      border: 1px solid #B8C1DE;
      border-radius: 4px;
      margin-top: 3px;
      
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 3px;
        width: 4px;
        height: 8px;
        border: solid black;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  
    .checkBoxContainer input:checked ~ .checkmark {
      background-color: white;
    }
  
    input:checked ~ .checkmark:after {
      display: block;
    }
  }
  