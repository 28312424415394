.icon-button {
    background-color: transparent;
    color: #69768B;
    transition-property: background-color;
    transition-duration: 200ms;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    line-height: 24px;
    span{
      display: flex;
      width: 20px;
      height: 20px;
      background-color: #2F2A89;
      color: #ffffff;
      border-radius: 5px;
    }
  }