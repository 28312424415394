.scheduleUsageModalContainer {
  font-family: "Poppins";
  .mainDescription {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #69768b;
  }
  .secondDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #69768b;
    margin-top: 38px;
  }
  .firstRow {
    margin-top: 30px;
    .indItem {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #69768b;
      .indicator {
        width: 10px;
        height: 8px;
        border-radius: 74px;
      }
      .weekDay {
        background-color: #6f80d6;
      }
      .weekEnd {
        background-color: #29bcc6;
      }
      .weekNotSchedule {
        background-color: #e4e4e4;
      }
    }
  }
  .secondRow {
    margin-top: 30px;
    color: #69768b;
    .spaceLabel {
      background: #f9f9f9;
      border-radius: 49px;
      height: 30px;
      color: #69768b;
    }
    .spaceLabel2 {
      color: #383941;
    }
  }
}
