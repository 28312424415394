.appSelectInput {
  background: #ffffff;
  border: 1px solid #eaecf3;
  width: 100%;
  height: 40px;
  padding: 0 6px;
  &.hasDanger {
    border: 1px solid #d74242ed;
  }
}

.opacity {
  opacity: 0.5;
}
