.color01 {
  border: solid 2px #f44336;
  background-color: #f443361c;
  color: #f44336;
}
.color02 {
  border: solid 2px #9c27b0;
  background-color: #9c27b01c;
  color: #9c27b0;
}
.color03 {
  border: solid 2px #3f51b5;
  background-color: #3f51b51c;
  color: #3f51b5;
}
.color04 {
  border: solid 2px #2196f3;
  background-color: #2196f31c;
  color: #2196f3;
}
.color05 {
  border: solid 2px #00bcd4;
  background-color: #00bcd41c;
  color: #00bcd4;
}
.color06 {
  border: solid 2px #009688;
  background-color: #0096881c;
  color: #009688;
}
.color07 {
  border: solid 2px #8bc34a;
  background-color: #8bc34a1c;
  color: #8bc34a;
}
.color08 {
  border: solid 2px #ffc107;
  background-color: #ffc1071c;
  color: #ffc107;
}
.color09 {
  border: solid 2px #795548;
  background-color: #7955481c;
  color: #795548;
}
.color10 {
  border: solid 2px #607d8b;
  background-color: #607d8b1c;
  color: #607d8b;
}

.color01b {
  background-color: #f44336;
}
.color02b {
  background-color: #9c27b0;
}
.color03b {
  background-color: #3f51b5;
}
.color04b {
  background-color: #2196f3;
}
.color05b {
  background-color: #00bcd4;
}
.color06b {
  background-color: #009688;
}
.color07b {
  background-color: #8bc34a;
}
.color08b {
  background-color: #ffc107;
}
.color09b {
  background-color: #795548;
}
.color10b {
  background-color: #607d8b;
}
